import {
  NavigationMegaMenuGroup,
  NavigationItem,
  NavigationClickableMenuItem,
} from './navigation-props';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useI18n } from 'next-localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import dynamic from 'next/dynamic';

const NavigationElement = dynamic(() => import('./NavigationElement'), {
  ssr: false,
});
type NavigationMegaMenuProps = NavigationClickableMenuItem & {
  navigationItem?: NavigationMegaMenuGroup;
  active: boolean;
  mobile?: boolean;
};

const columnsCssClasses = {
  '3column': 'container grid lg:grid-cols-4 columns-1 lg:my-4 lg:mt-8',
  '4column': 'container grid lg:grid-cols-4 columns-1 lg:my-4 lg:mt-8',
  '5column': 'container grid lg:grid-cols-5 columns-1 lg:my-4 lg:mt-8',
};

const NavigationMegaMenu = (props: NavigationMegaMenuProps): JSX.Element => {
  const heading = props.navigationItem?.text;
  const megaContentTitle = props.navigationItem?.heading;
  const { t } = useI18n();
  const children = props.navigationItem?.children?.results as NavigationItem[];

  const totalColumns = `${children.length.toString()}column`;
  const columnsClass = columnsCssClasses[totalColumns as '3column' | '4column' | '5column'];

  const onClickCloseHandler = (): void => {
    props.onMenuClick();
  };

  const megaMenuContent = props.active && (
    <div
      onClick={(e): void => e.stopPropagation()}
      className="absolute bg-neutral-medium text-left w-full left-0 h-auto py-14 pl-28 pr-11 hover:cursor-auto"
    >
      <div className="flex justify-between pt-4">
        {megaContentTitle && (
          <div className="text-white">
            <Text field={megaContentTitle.jsonValue} tag="div" className="text-28 font-bold" />
          </div>
        )}
        <div>
          <button type="button" onClick={onClickCloseHandler}>
            <FontAwesomeIcon icon={faXmark} className="text-white w-full" />
            <span className="sr-only">{t('Close')}</span>
          </button>
        </div>
      </div>
      {children && (
        <ul className={`${columnsClass} navigation`}>
          {children.map((child, index) => (
            <li key={index} className="flex">
              <NavigationElement navigationItem={child} onLinkClick={props.onLinkClick} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  const mobileMegaMenuContent = props.active && props.mobile && (
    <div
      onClick={(e): void => e.stopPropagation()}
      className="bg-neutral-dark/50 text-left w-full left-0 h-auto pl-4 pr-2 pb-9"
    >
      {megaContentTitle && (
        <div className="text-white">
          <Text field={megaContentTitle.jsonValue} tag="div" className="text-base font-bold pt-5" />
        </div>
      )}
      <div className="border-b-1 border-neutral-medium my-5" />
      {children && (
        <ul className="flex flex-col">
          {children.map((child, index) => (
            <li key={index} className="">
              <NavigationElement
                navigationItem={child}
                lastColumn={children.length === index + 1}
                onLinkClick={props.onLinkClick}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  const onClickMenuHandler = (): void => {
    props.onMenuClick();
  };

  return (
    <>
      <div
        className={`${props.className ?? ''} flex items-center justify-between`}
        onClick={onClickMenuHandler}
      >
        <div className="w-full text-base lg:text-lg leading-11">
          <Text
            field={heading}
            tag="span"
            className={`font-avidwalsheimbold ${props.mobile ? '' : ''}`}
          />
        </div>

        <div
          className={`w-3 h-3 flex items-center ${
            props.mobile ? 'text-accent-cool pr-4 w-8 h-7' : 'ml-2'
          }`}
        >
          <FontAwesomeIcon icon={props.active ? faChevronUp : faChevronDown} />
        </div>
      </div>
      {props.mobile ? mobileMegaMenuContent : megaMenuContent}
    </>
  );
};

export default NavigationMegaMenu;
